<template>
    <div class="protection_works">
        <taskCommon
            :title="title"
            :currentTree="currentTree"
            :list="list"
            :snSearch="snSearch"
            :listConfig="listConfig"
            :defaultProps="defaultProps"
            widthValue="280px"
            @handleChange="handleChange"
        >
            <slot>
                <Mymap
                    :mapData="mapData"
                    class="map"
                    ref="myMap"
                    @initmap="initMap"
                ></Mymap>
                <Dialog
                    ref="dialog"
                    :dialogData.sync="dialogData"
                    :ycysMc="ycysMc"
                    @closeDisesaseDialog="closeDisesaseDialog"
                    @saveSuccess="saveSuccess"
                >
                </Dialog>
            </slot>
        </taskCommon>
    </div>
</template>
<script>
import taskCommon from "../task-common";
import mixins from "../mixins";
import Dialog from "./protection_works_dialog";
import { mapActions, mapGetters } from "vuex";
export default {
    name: "protection_works",
    components: {
        taskCommon,
        Dialog,
    },
    mixins: [mixins],
    data() {
        return {
            mapData: {
                dxtState: true, //地形图
                maxZoom: 20,
                zoom: 12.5,
                areaState: true,
            },
            title: "",
            currentId: 1503,
            list: [],
            listConfig: {
                name: "BHGCMC",
                value: "ID",
            },
            basicMap: null,
            ycysMc: "",
            selectedId: "",
            selectedIndex: 0,
            areaLayers: null,
            defaultProps: {
                children: "child",
                label: "BHGCMC",
            },
        };
    },
    computed: {
        ...mapGetters(["system"]),
    },
    mounted() {
        switch (this.system) {
            case "system1":
                this.title = "保护工程";
                break;
            case "system2":
                this.title = "林业项目";
                break;
        }
        this.dialogData.title = "保护工程监测配置";
    },
    methods: {
        ...mapActions(["getProtectionList"]),
        handleClose() {},
        // 获得保护工程项目列表
        async getBhgcList() {
            let depmentid = "";
            if (this.system == "system2") {
                depmentid = localStorage.departmentId;
            }
            let res = await this.getProtectionList({
                ItemCode: this.currentId,
                depmentid: depmentid,
            });
            this.list = res;
            this.initZone();
        },
        initZone() {
            // 渲染保护范围
            let _this = this;
            this.areaLayers.clearLayers();
            this.list.forEach((zone, index) => {
                if (!zone.GCXMZB) return false;
                let geoJson = JSON.parse(zone.GCXMZB);
                // eslint-disable-next-line
                let layer = L.geoJSON(geoJson, {
                    onEachFeature: function (feature, layer) {
                        layer.selfId = zone.ID;
                        layer.selfType = "polygon";
                        layer.QYMC = zone.BHGCMC;
                        _this.setFeatureEvents(layer, zone, index);
                        _this.areaLayers.addLayer(layer);
                        layer.bindPopup(zone.BHGCMC, {
                            closeButton: false,
                            closeOnClick: true,
                        });
                    },
                    style(feature) {
                        return {
                            color: "#1862ad",
                            fillColor: "#1862ad",
                            weight: 2,
                        };
                    },
                });
                if (
                    this.$route &&
                    this.$route.params &&
                    this.$route.params.data &&
                    this.$route.params.data.jcdxid &&
                    this.$route.params.data.jcdxid === zone.ID
                ) {
                    this.basicMap.fitBounds(layer.getBounds());
                    let latlng = layer.getBounds().getCenter();
                    L.popup({
                        closeOnClick: false,
                        closeButton: false,
                        offset: [0, 5],
                        minWidth: 12,
                    })
                        .setLatLng(latlng)
                        .setContent(zone.BHGCMC)
                        .openOn(this.basicMap);
                }
            });
        },
        setFeatureEvents(feature, zone, index) {
            // 巡查区的事件
            let _this = this;
            feature.on("click", function (e) {
                // eslint-disable-next-line
                L.DomEvent.stopPropagation(e);
                _this.changeList([
                    feature.selfId,
                    feature.QYMC,
                    index,
                    0,
                    zone,
                ]);
            });
            feature.on("mouseover", function (e) {
                this.openPopup();
            });
            feature.on("mouseout", function (e) {
                this.closePopup();
            });
        },
        async changeList(val) {
            // 点击遗产要素列表，直接显示弹出框
            this.currentTree = val[0];
            this.ycysMc = val[1];
            this.selectedIndex = val[2];
            this.getJcDetail(val);
        },
        // 从列表视图点击 获得工程监测配置详情
        getJcDetail(item) {
            this.selectedId = item[0];
            this.dialogData.title = `${this.ycysMc}-监测任务配置`;
            this.dialogData.dialog = true;
            this.$refs.dialog.getDetail(item);
        },
        // 关闭弹框时 清除选中
        closeDisesaseDialog() {
            this.selectedId = "";
        },
        async initMap(map) {
            this.basicMap = map;
            // eslint-disable-next-line
            let areaLayers = L.featureGroup().addTo(this.basicMap);
            this.areaLayers = areaLayers;
            await this.getBhgcList();
        },
        // 保存成功后 重新获得工程范围列表
        async saveSuccess() {
            await this.getBhgcList();
        },
    },
};
</script>
<style lang="scss" scoped>
.protection_works {
}
</style>